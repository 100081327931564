import React, { useState, useEffect } from 'react';
import { FaSearchPlus } from "react-icons/fa";

const ImageChanger = () => {
    const images = {
      blue: 'https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/Inteerior-Painting%2FAzul.jpg?alt=media&token=7fdebeac-487a-4f95-a6a1-4ab76c021c75',
      red: 'https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/Inteerior-Painting%2FRojo.jpg?alt=media&token=237e9be5-40b4-4661-9652-dfdafd3f4b9f',
      orange: 'https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/Inteerior-Painting%2FNaranja.jpg?alt=media&token=b6dded4c-a527-4cfa-9624-608c692d4aa1',
      gray: 'https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/Inteerior-Painting%2FGris.jpg?alt=media&token=94984afd-357b-4c84-bf03-eb68296053cd',
      beige: 'https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/Inteerior-Painting%2FBeige.jpg?alt=media&token=2e908199-d0d2-4cdf-b547-8fed6e2164e2',
    };

    const defaultImage = 'https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/Inteerior-Painting%2Finterior-%20sin%20fondo.png?alt=media&token=ae391ad1-282e-4e33-bfda-017fd624aa43'; 
    const [imageSrc, setImageSrc] = useState(images.blue);
    const [selectedColor, setSelectedColor] = useState('#000000');

    const changeImage = (color) => {
        setImageSrc(images[color]);
    };

    useEffect(() => {
      const colorInput = document.getElementById('colorInput');
      const colorDisplay = document.getElementById('colorDisplay');
      const colorCode = document.getElementById('colorCode');

      const handleColorChange = (event) => {
          const selectedColor = event.target.value;
          colorDisplay.style.backgroundColor = selectedColor;
          colorCode.textContent = selectedColor;
          setSelectedColor(selectedColor);
      };

      const handleClick = () => {
        setImageSrc(defaultImage);
    };

      colorInput.addEventListener('input', handleColorChange);
      colorInput.addEventListener('click', handleClick);
      
      return () => {
        colorInput.removeEventListener('input', handleColorChange);
        colorInput.removeEventListener('click', handleClick);
      };
    }, []);

    return (
      <div className="w-4/5 mx-auto py-10">
        <div className="flex flex-wrap justify-center items-center ">
          <div id="colorDisplay" className={`md:w-2/4`}>
            <img
              className="w-full border-4 border-black"
              src={imageSrc}
              id="imgchange"
              alt="house"
            />
          </div>
          <div className="md:w-2/4 md:px-8 text-start">
            <a
              href="https://www.behr.com/consumer/colors/paint"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-[200px]"
                src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/BrandingExtra%2F6Iag7JuSIr0ciEGV481e%2Fbehr-1.png?alt=media&token=c6e45a5f-6026-41a5-982c-414dfddd7396"
                alt="Behr_logo"
              />
            </a>
            <h5 className="underline">Pick your favorite color:</h5>
            <div className="flex flex-wrap">
              {Object.keys(images).map((color) => (
                <div>
                <div
                  className="w-[60px] h-[60px] rounded cursor-pointer block m-3"
                  key={color}
                  id={color}
                  onClick={() => changeImage(color)}
                ></div>
                <p className='text-center capitalize'>{color}</p>
                </div>
              ))}
              <div className="mt-4 rounded flex flex-wrap gap-2">
                <span className="capitalize font-semibold">Or pick a custom color:</span>
                <div className='relative flex justify-center items-center'>
                <input
                  className="w-[80px] h-[80px] rounded cursor-pointer block relative"
                  type="color"
                  id="colorInput"
                />
                <FaSearchPlus className='absolute text-white block'/>
                </div>
                {/* <div id="colorDisplay" className="w-16 h-16 border mt-2"></div> */}
                <p id="colorCode" className="text-center">
                  {selectedColor}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ImageChanger;